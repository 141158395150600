import React from "react";

import EstimationSent from "../../../static/assets/images/alulux-estimation-sent-icon.png";
import "./ContactForm.scss";

const SuccessMessage = (props) => {
  return (
    <div className="success-message container-flex container-flex--direction-column container-flex--align-center container-flex--justify-center">
      <div className="sent-icon">
        <img src={EstimationSent} alt="estimation sent" loading="lazy"/>
      </div>
      <div className="title">{props.data.text[0]}</div>
      <div className="text">
        <div>{props.data.text[1]}</div>
        <div>{props.data.text[2]}</div>
      </div>
      <a href="/contact" className="btn-secondary">
        {props.data.button}
      </a>
    </div>
  );
};

export default SuccessMessage;
