import React from "react";
import Container from "../Container/Container";
import ContactForm from "./ContactForm";
import "./ContactUs.scss";

const ContactUsContainer = (props) => {
  const contactNumbers =
    props.data.contactUsInformation[1].contactInformationBlockInformation.split(
      ";"
    );

  return (
    <Container>
      <div className="contactUsContainer margin-top-100">
        <div className="contactUsContainer-inner">
          <div className="titles">
            <div className="titles-first">
              {props.data.contactUsHeaderText[0]}
            </div>
            <div className="titles-second">
              {props.data.contactUsHeaderText[1]}
            </div>
          </div>
          <div className="background-images">
            {props.data.contactUsBackgroundImages.map((image, i) => {
              return (
                <img
                  src={`https:${image.file.url}`}
                  alt={image.description}
                  key={i}
                  loading="lazy"
                />
              );
            })}
          </div>
          <div className="social-contact container-flex container-inner-w--per-100">
            <div className="social-contact--links container-inner-w--per-50">
              <div className="title">{props.data.contactUsHeaderText[2]}</div>
              <div className="social-media container-flex">
                {props.data.contactUsSocial.buttonsContainer.map(
                  (social, i) => {
                    return (
                      <a
                        href={social.socialMediaLink}
                        title={social.socialMediaTitle}
                        key={i}
                      >
                        <img
                          src={`https:${social.socialMediaIcon.file.url}`}
                          alt={social.socialMediaIcon.description}
                          loading="lazy"
                        />
                      </a>
                    );
                  }
                )}
              </div>
              <div className="information">
                <div className="text">
                  {" "}
                  {
                    props.data.contactUsInformation[0]
                      .contactInformationBlockTitle
                  }
                </div>
                <div className="link">
                  <a
                    href={
                      props.data.contactUsInformation[0]
                        .contactInformationBlockLocationLink.internal.content
                    }
                    target="_blank"
                    rel="noreferrer"
                  >
                    {
                      props.data.contactUsInformation[0]
                        .contactInformationBlockInformation
                    }
                  </a>
                </div>

                <div className="text">
                  {
                    props.data.contactUsInformation[1]
                      .contactInformationBlockTitle
                  }
                </div>
                <div className="link">
                  {contactNumbers.map((number, index) => (
                    <div key={index}>
                      <a href={`tel:${number.trim()}`}>{number.trim()}</a>
                    </div>
                  ))}
                </div>

                <div className="text">
                  {
                    props.data.contactUsInformation[2]
                      .contactInformationBlockTitle
                  }
                </div>
                <div className="link">
                  <a
                    href={`mailto:${props.data.contactUsInformation[2].contactInformationBlockInformation}`}
                  >
                    {
                      props.data.contactUsInformation[2]
                        .contactInformationBlockInformation
                    }
                  </a>
                </div>
              </div>
            </div>

            <div className="social-contact--form container-flex container-flex--justify-center container-inner-w--per-50">
              <ContactForm data={props.data.contactForm} />
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default ContactUsContainer;
