import React, { useEffect, useState, useRef } from "react";
import { useForm } from "react-hook-form";
import SuccessMessage from "./SuccessMessage";
import "./ContactForm.scss";
import ReCAPTCHA from "react-google-recaptcha";

const ContactForm = (props) => {
  const successMessageData = {
    text: props.data.contactFormSuccessMessage,
    button: props.data.contactFormButton[1].buttonText,
  };
  const [success, setSuccess] = useState(false);
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(true);

  const handleCheckboxChange = (event) => {
    setIsCheckboxChecked(event.target.checked);
  };

  const reCaptchaRef = useRef(<ReCAPTCHA />);

  const {
    register,
    handleSubmit,
    reset,
    trigger,
    formState: { errors, isSubmitSuccessful },
  } = useForm();

  const sendData = async (objectReceived) => {
    const generateFormId = () => {
      const randomNumber = Math.floor(Math.random() * 1000000);
      const timestamp = Date.now();
      const formId = `${timestamp}-${randomNumber}`;
      return formId;
    };

    function jsonToBase64(jsonObj) {
      const jsonString = JSON.stringify(jsonObj);
      const base64String = btoa(jsonString);
      return base64String;
    }

    const formPayload = {
      data: {
        formId: generateFormId(),
        payload: objectReceived,
      },
    };

    console.log(formPayload)
    try {
      const response = await fetch(
        "https://lhwku2ajca.execute-api.us-east-1.amazonaws.com/default/Alulux_Contact_Form_Submission",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "x-api-key": "Z37gWigRVD40UClREc1hy1qvvVi1XzHd65piaBJM",
          },
          body: JSON.stringify(jsonToBase64(formPayload)),
        }
      );

      const data = await response.json();
      console.log(data)
      return data;
    } catch (error) {
      return error;
    }
  };

  // reset field when form submitted
  useEffect(() => {
    if (isSubmitSuccessful) {
      reset();
    }
  }, [isSubmitSuccessful, reset]);

  return (
    <div
      className={
        success
          ? "form-container container-flex container-flex--justify-center"
          : "form-container"
      }
    >
      <ReCAPTCHA
        sitekey="6LehIakkAAAAAJCV5QoMXPa7lA2i9SN_PY4T7cSS"
        size="invisible"
        ref={reCaptchaRef}
      />

      <form className={success ? "no-display" : ""} id="contact-form">
        <div className="title">{props.data.contactFormTitle}</div>
        <div className="input-container container-flex margin-bottom-35">
          <div className="container-flex container-flex--direction-column container-inner-w--per-50">
            <label className="form-label" htmlFor="nameField">
              {props.data.contactFormInputFields[0]}
            </label>
            <input
              id="nameField"
              {...register("first_name", {
                required: "Enter your first name",
                pattern: {
                  value: /^[a-zA-Z][a-zA-Z ]*$/,
                  message: "This field accepts only letters",
                },
                maxLength: {
                  value: 25,
                  message: "Maximum length is 25",
                },
                minLength: {
                  value: 2,
                  message: "Minimum length is 2",
                },
              })}
              className={errors.first_name ? "form-input error" : "form-input"}
              placeholder="First Name"
              onKeyUp={(event) => {
                trigger("first_name");
                const onlyLetters = event.target.value.replace(
                  /[^a-zA-Z]/g,
                  ""
                ); // Remove digits or symbols from input
                event.target.value = onlyLetters;
              }}
            />
            {errors.first_name && (
              <div className="error-text">{errors.first_name.message}</div>
            )}
          </div>

          <div className="container-flex container-flex--direction-column container-inner-w--per-50">
            <label className="form-label" htmlFor="surnameField">
              {props.data.contactFormInputFields[1]}
            </label>
            <input
              id="surnameField"
              {...register("last_name", {
                required: "Enter your last name",
                pattern: {
                  value: /^[a-zA-Z][a-zA-Z ]*$/,
                  message: "This field accepts only letters",
                },
                maxLength: {
                  value: 25,
                  message: "Maximum length is 25",
                },
                minLength: {
                  value: 2,
                  message: "Minimum length is 2",
                },
              })}
              className={errors.last_name ? "form-input error" : "form-input"}
              placeholder="Last Name"
              onKeyUp={(event) => {
                trigger("last_name");
                const onlyLetters = event.target.value.replace(
                  /[^a-zA-Z]/g,
                  ""
                ); // Remove digits or symbols from input
                event.target.value = onlyLetters;
              }}
            />
            {errors.last_name && (
              <div className="error-text">{errors.last_name.message}</div>
            )}
          </div>
        </div>

        <div className="input-container container-flex margin-bottom-35">
          <div className="container-flex container-flex--direction-column container-inner-w--per-50">
            <label className="form-label" htmlFor="emailField">
              {props.data.contactFormInputFields[2]}
            </label>
            <input
              id="emailField"
              {...register("email", {
                required: "Enter your e-mail",
                pattern: {
                  value: /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/,
                  message: "Please enter a valid e-mail",
                },
              })}
              className={errors.email ? "form-input error" : "form-input"}
              placeholder="Email"
              onKeyUp={() => trigger("email")}
            />
            {errors.email && (
              <div className="error-text">{errors.email.message}</div>
            )}
          </div>

          <div className="container-flex container-flex--direction-column container-inner-w--per-50">
            <label className="form-label" htmlFor="phoneField">
              {props.data.contactFormInputFields[3]}
            </label>
            <input
              id="phoneField"
              {...register("phone_number", {
                minLength: {
                  value: 14,
                  message:
                    "Please enter a valid phone number, e.g: (xxx) xxx-xxxx",
                },
              })}
              className={
                errors.phone_number ? "form-input error" : "form-input"
              }
              placeholder="Phone Number"
              onKeyUp={(event) => {
                trigger("phone_number");
                const input = event.target.value.replace(/\D/g, ""); // Remove non-digits from input
                const formatted = input.replace(
                  /^(\d{3})(\d{0,3})(\d{4})/,
                  "($1) $2-$3"
                ); // Format the input with () and hyphens
                if (formatted.length > 14) {
                  event.target.value = formatted.slice(0, 14);
                } else {
                  event.target.value = formatted;
                }
              }}
            />
            {errors.phone_number && (
              <div className="error-text">{errors.phone_number.message}</div>
            )}
          </div>
        </div>

        <div className="container-flex container-flex--direction-column">
          <label className="form-label" htmlFor="messageField">
            {props.data.contactFormInputFields[4]}
          </label>
          <textarea
            id="messageField"
            {...register("message", {
              maxLength: {
                value: 350,
                message: "You have passed the maximum length",
              },
              minLength: {
                value: 5,
                message: "Please explain a little further",
              },
            })}
            className={
              errors.message ? "form-input message error" : "form-input message"
            }
            placeholder="Message"
            onKeyUp={() => trigger("message")}
          ></textarea>
          {errors.message && (
            <div className="error-text">{errors.message.message}</div>
          )}
        </div>
        <div className="required-text">
          {props.data.contactFormInputFields[5]}
        </div>

        <div className="checkbox-text container-flex container-flex--align-center">
          <input
            type="checkbox"
            id="check"
            {...register("get_updates")}
            checked={isCheckboxChecked}
            onClick={handleCheckboxChange}
          />
          <label htmlFor="check">{props.data.contactFormInputFields[6]}</label>
        </div>

        <div>
          <button
            className="btn-primary"
            type="submit"
            id="contact-form-button"
            onClick={handleSubmit(async (data) => {
              const token = await reCaptchaRef.current.executeAsync();
              reCaptchaRef.current.reset();
              var res = await sendData(data);
              res.statusCode === 200 ? setSuccess(true) : setSuccess(false);
            })}
          >
            {props.data.contactFormButton[0].buttonText}
          </button>
        </div>
      </form>

      {success && <SuccessMessage data={successMessageData} />}
    </div>
  );
};

export default ContactForm;
