import React, { useState, useEffect } from "react";
import { graphql } from "gatsby";
// Style Imports
import Layout from "../../components/Layout/Layout";
import ContactUsContainer from "../../components/ContactForm/ContactUsContainer";

export default function Contact({ data }) {
  const [error, setError] = useState(null);
  useEffect(() => {
    try {
      if (!data) {
        throw new Error("Data not available");
      }
    } catch (error) {
      setError(error);
    }
  }, [data]);

  if (error) {
    return <span>{error.map((err) => err.message).join(",")}</span>;
  }

  return (
    <Layout
      pageTitle={"Contact"}
      navData={
        data.allContentfulWebPages.edges[0].node.pages[4].pageLayout.navigation
      }
      footerData={
        data.allContentfulWebPages.edges[0].node.pages[0].pageLayout.footer
      }
    >
      <ContactUsContainer
        data={
          data.allContentfulWebPages.edges[0].node.pages[4].pageLayout
            .layoutMain[0]
        }
      />
    </Layout>
  );
}

export const contactPageData = graphql`
  query ContactPageQuery {
    allContentfulWebPages {
      edges {
        node {
          pages {
            pageLayout {
              navigation {
                navigationPages {
                  pageTitle
                  pageSlug
                  pageLayout {
                    layoutMain {
                      ... on ContentfulContainer {
                        containerItems {
                          ... on ContentfulCategoryTeaser {
                            category {
                              categoryInnerTitle
                              categorySlug
                              categoryProducts {
                                product {
                                  productInnerTitle
                                  productSlug
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
                navigationImages {
                  file {
                    url
                  }
                  description
                }
                navigationMediaIcons {
                  socialMediaIcon {
                    file {
                      url
                    }
                    description
                  }
                  socialMediaTitle
                  socialMediaLink
                }
              }
              layoutMain {
                ... on ContentfulContactUsContainer {
                  contactUsHeaderText
                  contactUsInformation {
                    contactInformationBlockTitle
                    contactInformationBlockInformation
                    contactInformationBlockLocationLink {
                      internal {
                        content
                      }
                    }
                  }
                  contactUsSocial {
                    buttonsContainer {
                      socialMediaTitle
                      socialMediaIcon {
                        description
                        file {
                          url
                        }
                      }
                      socialMediaLink
                    }
                  }
                  contactForm {
                    contactFormTitle
                    contactFormInputFields
                    contactFormButton {
                      buttonText
                    }
                    contactFormSuccessMessage
                  }
                  contactUsBackgroundImages {
                    description
                    file {
                      url
                    }
                  }
                }
              }
              footer {
                subscribeText
                subscribeDescription
                footerButtonText
                blockTitleFollowUs
                socialMediaLogosFooter {
                  socialMediaIcon {
                    file {
                      url
                    }
                  }
                  socialMediaLink
                }
                followUsDescription
                blockTitleAddress
                blockInfoAddress
                blockTitleCallUs
                blockInfoCallUs
                blockTitleWriteUs
                blockInfoWriteUs
                blockTitleExplore
                footerPages {
                  pageTitle
                  pageSlug
                }
                copyrightText
                googleMapsAddress {
                  googleMapsAddress
                }
              }
            }
          }
        }
      }
    }
  }
`;
