import React from "react";

const Container = ({ children, as = "div", width }) => {
  const Tag = as;

  return (
    <Tag
      style={{
        maxWidth: `${width}`,
        margin: "0 auto",
        padding: "$space-3xl $space-3xl",
      }}
    >
      {children}
    </Tag>
  );
};

export default Container;
